.unauthed-route__container {
  height: 100vh;
  width: calc(100% - 1em);
  display: flex;
  justify-content: center;
  align-items: center;
  /* Summer vector created by winkimages - www.freepik.com */
  background: url('../assets/leaf_pattern.jpg') repeat fixed;
  background-size: 500px;
  padding: 0.5em;
}

.unauthed-route__contents {
  padding: 2em;
  word-break: normal;
}
