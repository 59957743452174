.event-section__container {
  padding: 0.5em 0;
}

.event-section__contents {
  padding: 0.5em;
}

.event-section__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-section__body {
  margin-top: 0.5em;
}

.event-section__dates {
  margin: 0.5em 0;
}

.event-section__date-list {
  width: 100%;
}

.event-section__date {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiExpansionPanelDetails-root {
  max-height: 5em;
  overflow: scroll;
}
