.event-section-picker__container {
  display: flex;
  align-items: center;
  margin: 0.5em 0;
}

.event-section-picker__radio-group {
  margin-left: 1em;
}

.event-section-picker__picker {
  max-width: 6em;
  height: 100%;
}
