.list-controls__container {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.list-controls__sorting-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
}

.list-controls__split-sorting-button {
  margin-left: 0.5em;
}
