stripes {
  --white-1: rgba(255, 255, 255, 0.5);
  --white-2: rgba(255, 255, 255, 0.2);
}

.image-upload__dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  height: 100px;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  border: 2px dashed rgba(255, 255, 255, 0.4);
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3) 10px,
    rgba(255, 255, 255, 0.1) 10px,
    rgba(255, 255, 255, 0.1) 20px
  );
  border-radius: 8px;
  margin: 0.5em 0;
}

.image-upload__input-label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.image-upload__button-area {
  height: calc(100% - 4em);
  display: flex;
}

.image-upload__button {
  align-self: flex-end;
  margin-bottom: -0.5em;
}

.image-upload__button-container {
  padding: 1em 1em 0.5em 1em;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-right: 0.5em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}
