.search-bar__container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0;
}

.search-bar__icon {
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
