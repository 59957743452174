.inspector-panel__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inspector-panel__img {
  width: 80%;
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.inspector-panel__bubble {
  margin-bottom: 7em;
  animation: float 5s infinite;
}
