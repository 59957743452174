@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:ital,wght@0,400;0,800;1,400&family=Roboto+Mono&display=swap);
.bubble {
  position: relative;
  width: 250px;
  height: 120px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #ffffff; */
  background: #DBFFE1;
  border-radius: 20px;
  /* border: #7f7f7f solid 1px; */
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.2);
}

.bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 16px 15px 0;
  border-color: #DBFFE1 transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -14px;
  left: 110px;
  
}

.bubble:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 16px 15px 0;
  border-color: rgba(0, 0, 0, 0.2) transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -17px;
  left: 111px;
}

.event-section__container {
  padding: 0.5em 0;
}

.event-section__contents {
  padding: 0.5em;
}

.event-section__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-section__body {
  margin-top: 0.5em;
}

.event-section__dates {
  margin: 0.5em 0;
}

.event-section__date-list {
  width: 100%;
}

.event-section__date {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiExpansionPanelDetails-root {
  max-height: 5em;
  overflow: scroll;
}

.event-section-picker__container {
  display: flex;
  align-items: center;
  margin: 0.5em 0;
}

.event-section-picker__radio-group {
  margin-left: 1em;
}

.event-section-picker__picker {
  max-width: 6em;
  height: 100%;
}

stripes {
  --white-1: rgba(255, 255, 255, 0.5);
  --white-2: rgba(255, 255, 255, 0.2);
}

.image-upload__dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  height: 100px;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  border: 2px dashed rgba(255, 255, 255, 0.4);
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3) 10px,
    rgba(255, 255, 255, 0.1) 10px,
    rgba(255, 255, 255, 0.1) 20px
  );
  border-radius: 8px;
  margin: 0.5em 0;
}

.image-upload__input-label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.image-upload__button-area {
  height: calc(100% - 4em);
  display: flex;
}

.image-upload__button {
  align-self: flex-end;
  margin-bottom: -0.5em;
}

.image-upload__button-container {
  padding: 1em 1em 0.5em 1em;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-right: 0.5em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.inspector-panel__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inspector-panel__img {
  width: 80%;
}

@-webkit-keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.inspector-panel__bubble {
  margin-bottom: 7em;
  -webkit-animation: float 5s infinite;
          animation: float 5s infinite;
}

.inspector-panel-contents__container {
  overflow-y: scroll;
}

.inspector-panel__title-card {
  /* Background image by pikisuperstar - https://www.freepik.com/free-photos-vectors/background*/
  background: url(/static/media/card_pattern.95a8f13e.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-bottom: 1px solid #d3d3d3;
  min-height: calc(50% - 6em);
  display: flex;
  flex-direction: column;
}

.inspector-panel-content__title-card-top {
  display: flex;
  justify-content: space-between;
}

.inspector-panel-content__title-card-titles {
  word-break: break-word;
}

.inspector-panel-content__edit-fields {
  padding: 1em 1em 0.5em 1em;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-right: 0.5em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.inspector-panel-content__controls {
  margin-top: -1em;
  margin-left: auto;
  white-space: nowrap;
}

.inspector-panel-content__contents {
  padding: 0.5em;
  overflow-y: scroll;
}

.inspector-panel-content__add-fields {
  padding: 1em;
}

.inspector-panel-content__edit-buttons {
  margin-top: 1em;
}

.list-controls__container {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.list-controls__sorting-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
}

.list-controls__split-sorting-button {
  margin-left: 0.5em;
}

.plant-list-row-container {
  margin: 0.5em 0;
}

.plant-list-row {
  padding: 0.2em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-row__text {
  max-width: 90%;
}

.list-row__watering-days-number {
  /* height: 2.5em; */
  position: relative;
  top: -1.6em;
}

.list-row__buttons {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: -0.5em;
}

.list-row__menu-item-text {
  margin-left: 1em;
}

.plants {
  /* Summer vector created by winkimages - www.freepik.com */
  background: url(/static/media/leaf_pattern.f701062a.jpg) repeat fixed;
  background-size: 500px;
  height: 100vh;
}

.plants__bg-layer {
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.plants__container {
  display: flex;
  height: calc(100% - 4.5em);
}

.plants__lists {
  overflow-y: scroll;
  height: calc(100% - 4.5em - 4px);
}

.plants__attention-list {
  border: 2px solid rgba(76, 136, 119, 0.5);
  border-radius: 8px;
  padding: 0.5em;
  margin-bottom: 1em;
  background-color: rgba(179, 254, 191, 0.2);
}

.plants__list-spacer {
  height: 9em;
}

.plants__list-progress {
  display: flex;
  justify-content: center;
  margin-top: 5em;
}

.plants__inspector-panel-placeholder {
  width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #fff 10%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 10%);
  background-image: -ms-linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, #fff 10%);
  padding-bottom: 3em;
}

.plants__inspector-panel-img {
  max-width: 80%;
  height: 50%;
}

@-webkit-keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.plants__inspector-panel-bubble {
  margin-bottom: 7em;
  -webkit-animation: float 5s infinite;
          animation: float 5s infinite;
}

.sign-in__fields {
  display: flex;
  flex-direction: column;
}

.MuiLink-root:hover {
  cursor: pointer
}

.search-bar__container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0;
}

.search-bar__icon {
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sorting-button {
  margin: 0 0.1em;
}

.top-nav-bar__contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  box-shadow: 1px 0px 3px 3px rgba(0, 0, 0, 0.3);
}

.top-nav-bar__app-name {
  margin-right: 1em;
}

.top-nav-bar__user-panel {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.top-nav-bar__user-panel-button {
  margin-top: 1em;
}

.top-nav-bar__search-menu {
  display: flex;
}
.unauthed-route__container {
  height: 100vh;
  width: calc(100% - 1em);
  display: flex;
  justify-content: center;
  align-items: center;
  /* Summer vector created by winkimages - www.freepik.com */
  background: url(/static/media/leaf_pattern.f701062a.jpg) repeat fixed;
  background-size: 500px;
  padding: 0.5em;
}

.unauthed-route__contents {
  padding: 2em;
  word-break: normal;
}



html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

