.plants {
  /* Summer vector created by winkimages - www.freepik.com */
  background: url('../assets/leaf_pattern.jpg') repeat fixed;
  background-size: 500px;
  height: 100vh;
}

.plants__bg-layer {
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.plants__container {
  display: flex;
  height: calc(100% - 4.5em);
}

.plants__lists {
  overflow-y: scroll;
  height: calc(100% - 4.5em - 4px);
}

.plants__attention-list {
  border: 2px solid rgba(76, 136, 119, 0.5);
  border-radius: 8px;
  padding: 0.5em;
  margin-bottom: 1em;
  background-color: rgba(179, 254, 191, 0.2);
}

.plants__list-spacer {
  height: 9em;
}

.plants__list-progress {
  display: flex;
  justify-content: center;
  margin-top: 5em;
}

.plants__inspector-panel-placeholder {
  width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #fff 10%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 10%);
  background-image: -o-linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, #fff 10%);
  background-image: -moz-linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, #fff 10%);
  background-image: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, #fff 10%);
  background-image: -ms-linear-gradient(to right, rgba(255, 255, 255, 0.001) 0%, #fff 10%);
  padding-bottom: 3em;
}

.plants__inspector-panel-img {
  max-width: 80%;
  height: 50%;
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.plants__inspector-panel-bubble {
  margin-bottom: 7em;
  animation: float 5s infinite;
}
