.inspector-panel-contents__container {
  overflow-y: scroll;
}

.inspector-panel__title-card {
  /* Background image by pikisuperstar - https://www.freepik.com/free-photos-vectors/background*/
  background: url('../assets/card_pattern.jpg') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  border-bottom: 1px solid #d3d3d3;
  min-height: calc(50% - 6em);
  display: flex;
  flex-direction: column;
}

.inspector-panel-content__title-card-top {
  display: flex;
  justify-content: space-between;
}

.inspector-panel-content__title-card-titles {
  word-break: break-word;
}

.inspector-panel-content__edit-fields {
  padding: 1em 1em 0.5em 1em;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-right: 0.5em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.inspector-panel-content__controls {
  margin-top: -1em;
  margin-left: auto;
  white-space: nowrap;
}

.inspector-panel-content__contents {
  padding: 0.5em;
  overflow-y: scroll;
}

.inspector-panel-content__add-fields {
  padding: 1em;
}

.inspector-panel-content__edit-buttons {
  margin-top: 1em;
}
