.plant-list-row-container {
  margin: 0.5em 0;
}

.plant-list-row {
  padding: 0.2em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-row__text {
  max-width: 90%;
}

.list-row__watering-days-number {
  /* height: 2.5em; */
  position: relative;
  top: -1.6em;
}

.list-row__buttons {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: -0.5em;
}

.list-row__menu-item-text {
  margin-left: 1em;
}
